var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"mb-0"},[_c('CCardHeader',{staticClass:"p-2"},[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":_vm.order.id,"noTranslate":"","bold":""}}),_c('div',{staticClass:"ml-1"},[_c('SMessageOrderStatus',{attrs:{"id":_vm.order.status_id}})],1)],1)]),_c('CCardBody',{staticClass:"p-2"},[(_vm.display.includes('service_fee'))?_c('TTableAsForm',{attrs:{"data":_vm.order,"fields":_vm.serviceChargeFields,"splitLeft":7},scopedSlots:_vm._u([{key:"service_fee",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.order.service_fee_currency_id}})]}},{key:"service_fee_paid",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.order.service_fee_currency_id}})]}},{key:"service_fee_unsolved",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.order.service_fee_currency_id}})]}},{key:"service_fee_boxes",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.order.service_fee_currency_id}})]}}],null,false,3739186741)}):_vm._e(),(_vm.display.includes('purchase_cost'))?_c('TTableAsForm',{attrs:{"data":_vm.order,"fields":_vm.goodsMoneyFields,"splitLeft":7},scopedSlots:_vm._u([{key:"purchase_cost",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.order.purchase_cost_currency_id}})]}},{key:"purchase_cost_paid",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.order.purchase_cost_currency_id}})]}},{key:"purchase_cost_unsolved",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.order.purchase_cost_currency_id}})]}}],null,false,3304130838)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }