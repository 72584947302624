var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Create order credit","show":_vm.show},on:{"update:show":function($event){return _vm.$emit('update:show', $event)},"click-create":_vm.add},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('TButton',{attrs:{"content":"Create","options":{
        disabled:
          _vm.loading ||
          _vm.paymentLoading ||
          !_vm.data.payment_due_date ||
          !_vm.data.amount ||
          _vm.lodash.isEmpty(_vm.walletSelected),
      },"icon":"cil-plus"},on:{"click":_vm.add}})]},proxy:true}])},[(!_vm.lodash.isEmpty(_vm.walletSelected))?_c('div',[_c('TInputMoney',{staticClass:"mb-3",attrs:{"label":"Amount","value":_vm.data.amount,"currency":_vm.data.currency_id},on:{"update:value":function($event){return _vm.$set(_vm.data, "amount", $event)}}}),_c('TInputDateTime',{attrs:{"label":"Payment due date","value":_vm.data.payment_due_date},on:{"update:value":function($event){return _vm.$set(_vm.data, "payment_due_date", $event)}}})],1):_c('TMessageNoData',{staticClass:"mt-2",attrs:{"content":"Add wallets","color":"danger","bold":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }