var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Update suppliers","show":_vm.show,"size":"xl"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div')]},proxy:true}])},[_c('SSelectSupplier',{staticClass:"mb-3",attrs:{"value":_vm.item.supplier_id,"label":"Supplier"},on:{"update:value":[function($event){return _vm.$set(_vm.item, "supplier_id", $event)},function($event){_vm.item.supplier_id = $event}]}}),_c('CRow',[_c('CCol',{attrs:{"col":"6"}},[_c('TInputMoney',{staticClass:"mb-3",attrs:{"value":_vm.item.price,"label":"Price","currency":_vm.item.currency_id},on:{"update:value":function($event){_vm.item.price = $event}}})],1),_c('CCol',{attrs:{"col":"6"}},[_c('TInputNumber',{staticClass:"mb-3",attrs:{"value":_vm.item.quantity_items,"label":"Items in box"},on:{"update:value":function($event){_vm.item.quantity_items = $event}}})],1)],1),_c('div',{staticClass:"d-flex"},[_c('TButton',{staticClass:"ml-auto",attrs:{"icon":"cil-plus","content":"Add"},on:{"click":_vm.create}})],1),_c('TTableAdvance',{staticClass:"mt-3",attrs:{"items":_vm.product.suppliers,"fields":_vm.itemFields,"noPaginate":"","store":"product.products.detail","resource":"","noFilter":"","noClickRow":"","title":"Supplier list"},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"px-1 text-center",attrs:{"col":"12"}},[_c('TButtonRemove',{on:{"click":function($event){return _vm.removeSupplier(item.id)}}})],1)]}},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":""}})],1)]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":_vm.lodash.toNumber(item.pivot.price),"currency":item.currency_id}})],1)]}},{key:"note",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.note,"noTranslate":""}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }