var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"h-100 m-0"},[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"List of bills of lading","bold":""}}),_c('TSpinner',{attrs:{"loading":_vm.loading}}),_c('TButtonRefresh',{staticClass:"ml-auto",on:{"click":function($event){return _vm.$store.dispatch('warehouse.lading_bills_of_orders.fetch')}}})],1),(_vm.list)?_c('CCardBody',{staticClass:"w-100 mx-auto",staticStyle:{"max-width":"800px"}},[_c('div',{staticClass:"w-100"},_vm._l((_vm.list),function(ladingBill){return _c('CCol',{key:ladingBill.id,staticClass:"p-0 w-100"},[_c('CCard',{staticClass:"mb-2 w-100",attrs:{"borderColor":"primary"}},[_c('CCardHeader',{staticClass:"p-1",attrs:{"color":"primary"}},[_c('TMessage',{attrs:{"content":"Bill of lading code","color":"white","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('span',[_vm._v(":")]),_vm._v(" "+_vm._s(ladingBill.id)+" ")]},proxy:true}],null,true)})],1),_c('CCardBody',{staticClass:"p-2"},[_c('TTableAsForm',{staticClass:"mb-0",attrs:{"data":ladingBill,"fields":_vm.pivotLadingBillFields,"splitLeft":6,"addRowClasses":[]},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"dateOnly":""}})]}},{key:"freight_charge",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":ladingBill.currency_id}})]}},{key:"container",fn:function(ref){
var data = ref.data;
return [(data.container)?_c('SMessageContainer',{attrs:{"noBadge":"","container":data.container}}):_c('TMessageNotFound')]}},{key:"status",fn:function(ref){
var data = ref.data;
return [(data.container)?_c('div',{staticClass:"d-flex"},[_c('SMessageContainerStatus',{staticClass:"my-1",attrs:{"status":data.container.had_cut_off}}),(data.container.cut_off_date)?_c('TMessageDateTime',{staticClass:"ml-2 my-auto",attrs:{"content":data.container.cut_off_date,"dateOnly":""}}):_vm._e()],1):_vm._e()]}},{key:"from-to",fn:function(ref){
var data = ref.data;
return [(data.container)?_c('div',[(data.container.from_area_id)?_c('CBadge',{attrs:{"color":"primary"}},[_c('TMessage',{attrs:{"content":data.container.from_area_id,"uppercase":"","noTranslate":""}})],1):_vm._e(),_c('CIcon',{staticClass:"mx-1",attrs:{"name":"cil-arrow-circle-right"}}),(data)?_c('CBadge',{attrs:{"color":"info"}},[_c('TMessage',{attrs:{"content":data.container.to_area_id,"uppercase":"","noTranslate":""}})],1):_vm._e()],1):_vm._e()]}}],null,true)})],1)],1)],1)}),1),(!_vm.list.length)?_c('TMessageNoData'):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }