var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("edit",function(){return [(_vm.editing && !_vm.showEditModal)?_c('TInputEditable',{attrs:{"value":_vm.content},on:{"change":function($event){_vm.$emit('change', $event);
        _vm.setEditing(false);},"close":function($event){return _vm.setEditing(false)}},scopedSlots:_vm._u([{key:"input",fn:function(ref){
        var input = ref.input;
        var setInput = ref.setInput;
return [_c('TInputDateTime',{attrs:{"value":input,"dateOnly":_vm.dateOnly},on:{"update:value":setInput}})]}}],null,false,1734194177)}):_vm._e(),(_vm.editing && _vm.showEditModal)?_c('TModal',{attrs:{"title":"Dates","show":_vm.editing},on:{"update:show":function($event){_vm.editing=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span')]},proxy:true}],null,false,2058430185)},[(_vm.editing)?_c('TInputEditable',{staticClass:"w-100",attrs:{"value":_vm.content},on:{"change":function($event){_vm.$emit('change', $event);
          _vm.setEditing(false);},"close":function($event){return _vm.setEditing(false)}},scopedSlots:_vm._u([{key:"input",fn:function(ref){
          var input = ref.input;
          var setInput = ref.setInput;
return [_c('TInputDateTime',{staticClass:"w-100",attrs:{"value":input,"dateOnly":_vm.dateOnly},on:{"update:value":setInput}})]}}],null,false,2421587274)}):_vm._e()],1):_vm._e()]},{"editing":_vm.editing,"setEditing":_vm.setEditing}),_c('TMessage',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editing || _vm.dontHideWhenEditing),expression:"!editing || dontHideWhenEditing"}],attrs:{"content":_vm.formatTime,"size":_vm.small ? 'small' : null,"noTranslate":"","italic":_vm.editable,"creatable":_vm.creatable,"editable":_vm.editable,"removable":_vm.removable},on:{"click-edit":function($event){return _vm.showEdit()},"click-create":function($event){return _vm.showEdit()},"click-remove":function($event){_vm.showModalConfirm = true}}}),(_vm.removable)?_c('TModalConfirm',{staticClass:"text-dark",attrs:{"show":_vm.showModalConfirm},on:{"update:show":function($event){_vm.showModalConfirm=$event},"click-confirm":function($event){return _vm.$emit('click-remove')}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }