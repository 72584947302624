var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('SMessageShipmentMethod',{attrs:{"id":_vm.ladingBill.shipment_method_id,"iconOnly":""}}),_c('TMessage',{attrs:{"content":"Lading bill","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.ladingBill.id))])]},proxy:true}])}),_c('TSpinner',{attrs:{"loading":_vm.detail_loading}}),_c('div',{staticClass:"ml-auto d-flex"},[(_vm.ladingBill.freight_charge_unsolved)?_c('SButtonDebit',{staticClass:"mr-1",attrs:{"options":{ disabled: _vm.detail_loading }},on:{"click":_vm.debit}}):_vm._e(),(_vm.removable)?_c('TButtonDelete',{staticClass:"mr-1",attrs:{"variant":"","options":{ disabled: _vm.detail_loading }},on:{"click":_vm.removeLadingBill}}):_vm._e(),_c('TButtonRefresh',{attrs:{"options":{ disabled: _vm.detail_loading }},on:{"click":_vm.refresh}})],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"12","lg":"12","xl":"6"}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"fields":_vm.parameterFields,"data":_vm.ladingBill,"splitLeft":7,"title":"Detail"},scopedSlots:_vm._u([{key:"shipment_method_id",fn:function(ref){
var value = ref.value;
return [_c('SMessageShipmentMethod',{attrs:{"id":value,"editable":!_vm.detail_loading},on:{"change":function($event){return _vm.updateLadingBill('shipment_method_id', $event)}}})]}},{key:"volume",fn:function(ref){
var value = ref.value;
return [_c('TMessageVolume',{staticClass:"font-weight-bold",attrs:{"value":value}})]}},{key:"weight",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{staticClass:"font-weight-bold",attrs:{"value":value,"suffix":"kg"}})]}},{key:"volumetric_weight",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{staticClass:"font-weight-bold",attrs:{"value":value,"suffix":"kg"}})]}},{key:"customer",fn:function(ref){
var data = ref.data;
return [_c('TLink',{attrs:{"content":data.customer ? data.customer.name : data.customer_id,"to":_vm.lodash.getReferenceLink('user', data.customer_id)}})]}}])})],1),_c('CCol',{attrs:{"md":"12","lg":"12","xl":"6"}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"fields":_vm.detailFields,"data":_vm.ladingBill,"splitLeft":7,"title":"Container"},scopedSlots:_vm._u([{key:"container",fn:function(ref){
var value = ref.value;
return [(value)?_c('TLink',{attrs:{"content":value.name,"to":_vm.lodash.getReferenceLink('invoice', value.id)}}):_c('TMessageNotFound')]}},{key:"shipment_method",fn:function(ref){
var data = ref.data;
return [(data.container)?_c('SMessageShipmentMethod',{attrs:{"id":data.container.shipment_method_id}}):_vm._e()]}},{key:"status",fn:function(ref){
var data = ref.data;
return [(data.container)?_c('div',[_c('SMessageContainerStatus',{attrs:{"status":data.container.had_cut_off}}),(data.container.cut_off_date)?_c('TMessageDateTime',{attrs:{"content":data.container.cut_off_date,"dateOnly":""}}):_vm._e()],1):_vm._e()]}},{key:"from_area_id",fn:function(ref){
var data = ref.data;
return [(data.container)?_c('SMessageArea',{attrs:{"id":data.container.from_area_id}}):_vm._e()]}},{key:"to_area_id",fn:function(ref){
var data = ref.data;
return [(data.container)?_c('SMessageArea',{attrs:{"id":data.container.to_area_id}}):_vm._e()]}}])})],1),_c('CCol',{attrs:{"md":"12","lg":"12","xl":"6"}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"fields":_vm.methodFields,"data":_vm.ladingBill,"splitLeft":7,"title":"Charging method","addRowClasses":['py-1']},scopedSlots:_vm._u([{key:"type_freight_charge",fn:function(ref){
var value = ref.value;
return [_c('SMessageFreightChargeType',{attrs:{"id":value,"editable":!_vm.detail_loading},on:{"change":function($event){return _vm.updateLadingBill('type_freight_charge', $event)}}})]}},{key:"type_freight_range",fn:function(ref){
var value = ref.value;
return [_c('SMessageFreightRangeType',{attrs:{"id":value,"editable":!_vm.detail_loading},on:{"change":function($event){return _vm.updateLadingBill('type_freight_range', $event)}}})]}},{key:"automatic_freight_rate_update",fn:function(ref){
var value = ref.value;
return [_c('TSwitch',{attrs:{"checked":value,"disabled":_vm.detail_loading},on:{"change":function($event){return _vm.updateLadingBill('automatic_freight_rate_update', $event)}}})]}}])})],1),_c('CCol',{attrs:{"md":"12","lg":"12","xl":"6"}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"fields":_vm.additionalFields,"data":_vm.ladingBill,"splitLeft":7,"title":"Other","addRowClasses":['py-1']},scopedSlots:_vm._u([{key:"freight_charge_pay_for_order",fn:function(ref){
var value = ref.value;
return [_c('TSwitch',{attrs:{"checked":value,"disabled":_vm.detail_loading},on:{"change":function($event){return _vm.updateLadingBill('freight_charge_pay_for_order', $event)}}})]}},{key:"freight_charge_pay_for_order-header",fn:function(){return [_c('TMessage',{attrs:{"content":"Freight charge pay for order","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" ("+_vm._s(_vm.$t("Not in the contract"))+") ")]},proxy:true}])})]},proxy:true}])})],1)],1)],1)],1),_c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":"Value of Lading bill","bold":""}}),_c('TSpinner',{attrs:{"loading":_vm.detail_loading}})],1)]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"12","lg":"12","xl":"6"}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"fields":_vm.freightChargeFields,"data":_vm.ladingBill,"splitLeft":7,"title":"Freight charge"},scopedSlots:_vm._u([{key:"freight_charge",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.ladingBill.currency_id}})]}},{key:"freight_charge_debited",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.ladingBill.currency_id}})]}},{key:"freight_charge_outstanding",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.ladingBill.currency_id}})]}},{key:"freight_charge_paid",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.ladingBill.currency_id}})]}},{key:"freight_charge_unsolved",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.ladingBill.currency_id}})]}}])})],1),_c('CCol',{attrs:{"md":"12","lg":"12","xl":"6"}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"fields":_vm.chargeFields,"data":_vm.ladingBill,"splitLeft":7,"title":"Calculated value"},scopedSlots:_vm._u([{key:"freight_range",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value,"decimalLimit":4}})]}},{key:"discount",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"currency":_vm.ladingBill.currency_id,"amount":value,"editable":!_vm.detail_loading},on:{"change":function($event){return _vm.updateLadingBill('discount', $event)}}})]}},{key:"freight_rate",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"currency":_vm.ladingBill.currency_id,"amount":value,"editable":!_vm.detail_loading && !_vm.ladingBill.automatic_freight_rate_update},on:{"change":function($event){return _vm.updateLadingBill('freight_rate', $event)}}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }