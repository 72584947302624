var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TSelect',{staticClass:"m-0",attrs:{"label":_vm.label,"options":_vm.listC,"value":_vm.valueSelected,"selectOptions":{
    internalSearch: true,
    preserveSearch: true,
  },"normalizer":{ label: 'id', value: 'id' },"placeholder":_vm.placeholder,"loading":_vm.loading},on:{"select":_vm.select,"search":_vm.search},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
  var option = ref.option;
return [_c('TMessage',{attrs:{"content":option.id ? option.id : option.name,"noTranslate":"","truncate":1,"size":"small"}})]}},{key:"option",fn:function(ref){
  var option = ref.option;
return [_c('TMessage',{attrs:{"content":option.id ? option.id : option.name,"noTranslate":"","truncate":1}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }