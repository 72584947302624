var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.contract)?_c('CCard',[_c('CCardHeader',{staticClass:"d-flex"},[_c('TMessage',{staticClass:"my-auto",attrs:{"content":_vm.contract.id,"bold":"","noTranslate":""}}),_c('TSpinner',{attrs:{"loading":_vm.loading}}),_c('SMessageContractStatus',{staticClass:"my-auto ml-1",attrs:{"status":_vm.contract.status_id}}),_c('TButtonEnter',{staticClass:"ml-auto",attrs:{"to":_vm.lodash.getReferenceLink('contract', _vm.contract.id),"variant":"outline"}})],1),_c('CCardBody',{staticClass:"w-100 mx-auto"},[_c('div',[_c('TTableAsForm',{attrs:{"data":_vm.contract,"fields":_vm.contractFields,"splitLeft":6,"addRowClasses":[]},scopedSlots:_vm._u([{key:"start_date",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"dateOnly":""}})]}},{key:"end_date",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"dateOnly":""}})]}},{key:"service_fee",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.contract.service_fee_currency_id}})]}},{key:"purchase_cost",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.contract.purchase_cost_currency_id}})]}}],null,false,4266300486)})],1)])],1):_vm._e(),(_vm.checkEmpty)?_c('CCard',[_c('CCardHeader',[_c('TMessage',{attrs:{"content":"Contract","bold":""}})],1),_c('CCardBody',[_c('TMessageNoData')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }