var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CDataTable',_vm._b({attrs:{"border":"","fixed":"","column-filter":_vm.columnFilter,"pagination":_vm.pagination,"size":_vm.size,"items":_vm.items,"fields":_vm.advanceFields,"addTableClasses":"text-nowrap","loading":_vm.loading},on:{"filtered-items-change":_vm.itemsChanged},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('TMessage',{staticClass:"py-2",attrs:{"content":"Empty","italic":"","color":"muted"}})]},proxy:true},{key:"footer",fn:function(){return [(!_vm.noResult)?_c('tr',{staticClass:"bg-gray-100 text-dark"},[_vm._l((_vm.columnResults),function(column){return _vm._t(("result-" + (column.key)),function(){return [_c('th',{key:("result-" + (column.key))},[_vm._v(" "+_vm._s(column.result)+" ")])]},null,{
          field: column,
          total: _vm.calTotal(column.key, _vm.items),
        })})],2):_vm._e(),(_vm.columnInput)?_c('tr',[_vm._l((_vm.fields),function(field,index){return _vm._t(("input-" + (field.key)),function(){return [_c('th',{key:index},[_c('div',[(_vm.isShowInput(field))?_c('CInput',{staticClass:"mb-0",attrs:{"placeholder":field.placeholder,"type":field.inputType || 'text',"value":_vm.selected[field.key] ||
                _vm.dataInput[field.key] ||
                field.inputDefault},on:{"update:value":function($event){return _vm.onInput(field.key, $event, field)}}}):_vm._e()],1)])]},null,{
          selected: _vm.selected,
          dataInput: _vm.dataInput,
          field: field,
          eventUpdate: _vm.onInput,
        })})],2):_vm._e()]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),(_vm.$slots['details'])?{key:"details",fn:function(){return [_vm._t("details",null,null,_vm.slotData)]},proxy:true}:null],null,true)},'CDataTable',_vm.options,false))}
var staticRenderFns = []

export { render, staticRenderFns }