var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('label',{class:['font-weight-bold', { 'mb-0 col-form-label-lg': _vm.size == 'lg' }],attrs:{"for":"inputdatepicker"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.label))+" ")]):_vm._e(),_c('DatePicker',{staticClass:"w-100",attrs:{"id":"inputdatepicker","locale":{ id: _vm.locale },"mode":_vm.mode,"attributes":[
      {
        key: 'today',
        highlight: 'red',
        dates: new Date(),
      } ],"value":_vm.value * 1000,"model-config":_vm.modelConfig,"masks":_vm.masksConfig},on:{"input":_vm.setValue},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var inputValue = ref.inputValue;
    var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({class:['form-control', { 'form-control-lg': _vm.size == 'lg' }],attrs:{"placeholder":_vm.$t(_vm.placeholder)},domProps:{"value":inputValue}},inputEvents))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }