var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',{staticClass:"d-flex justify-content-between align-items-center"},[_c('TMessage',{attrs:{"content":"Shipment info","bold":""}}),_c('TSpinner',{attrs:{"loading":_vm.fetching}}),_c('TButton',{staticClass:"ml-auto",attrs:{"color":"success","variant":"outline","tooltip":"Change","icon":"cil-pencil","size":"sm"},on:{"click":function($event){_vm.showModalEdit = true}}})],1),_c('CCardBody',[(_vm.shipmentInfoEmpty)?_c('TMessageNoData'):_c('TTableAsForm',{attrs:{"data":_vm.shipment_infos,"fields":_vm.shipmentFields,"splitLeft":5},scopedSlots:_vm._u([{key:"consignee",fn:function(ref){
var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value,"editable":""},on:{"change":function($event){return _vm.updateShipmentInfo('consignee', $event)}}})]}},{key:"sender_name",fn:function(ref){
var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value,"editable":""},on:{"change":function($event){return _vm.updateShipmentInfo('sender_name', $event)}}})]}},{key:"sender_full_address",fn:function(ref){
var data = ref.data;
return [_c('SMessageAddress',{attrs:{"wardId":data.sender_ward_id,"address":data.sender_address || '',"content":data.sender_full_address,"editable":""},on:{"change":function($event){return _vm.$store.dispatch('order.shipment_infos.update', {
                id: _vm.orderId,
                attributes: {
                  sender_address: $event.address,
                  sender_ward_id: $event.ward_id,
                },
              })}}})]}},{key:"full_address",fn:function(ref){
              var data = ref.data;
return [_c('SMessageAddress',{attrs:{"wardId":data.ward_id,"address":data.address,"content":data.full_address,"editable":""},on:{"change":function($event){return _vm.$store.dispatch('order.shipment_infos.update', {
                id: _vm.orderId,
                attributes: $event,
              })}}})]}},{key:"tel",fn:function(ref){
              var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value,"editable":""},on:{"change":function($event){return _vm.updateShipmentInfo('tel', $event)}}})]}},{key:"sender_tel",fn:function(ref){
              var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value,"editable":""},on:{"change":function($event){return _vm.updateShipmentInfo('sender_tel', $event)}}})]}},{key:"note",fn:function(ref){
              var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value,"editable":""},on:{"change":function($event){return _vm.updateShipmentInfo('note', $event)}}})]}}])})],1)],1),_c('SModalSelectAddress',{key:_vm.keyModal,attrs:{"disabled":_vm.disabled,"show":_vm.showModalEdit,"userId":_vm.userId},on:{"update:show":function($event){_vm.showModalEdit=$event},"click-save":_vm.save}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }